import { COMMAND_ACCEPT_BOARD_QUESTIONNAIRE_SUGGESTION_TEMPLATE, ICommandRequest } from "@bigpi/cookbook";
import { Chip, Link } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import { WorkspaceBoardSuggestionsQuery } from "GraphQL/Generated/Apollo";
import { CommandContext } from "CommandContext/CommandContext";

// *********************************************
// Interfaces
// *********************************************/
export interface IWorkspaceBoardSuggestionProps {
  workspaceBoardSuggestion: NonNullable<WorkspaceBoardSuggestionsQuery["workspaceBoardSuggestions"]>[0];
}

// *********************************************
// Public methods
// *********************************************/
export function WorkspaceBoardSuggestion(props: IWorkspaceBoardSuggestionProps) {
  const { workspaceBoardSuggestion } = props;
  const { t } = useTranslation();
  const commandExecutor = useCommandExecutor();

  const applySuggestionRequestId = `workspace-board-shape-apply-suggestion-${workspaceBoardSuggestion.id}`;
  const applySuggestion = useCallback(async () => {
    const command = {
      ...COMMAND_ACCEPT_BOARD_QUESTIONNAIRE_SUGGESTION_TEMPLATE,
    };

    // Create the overrides/values that are not set automatically by CommandExecutor
    const commandRequest: Partial<ICommandRequest> = {
      commandContext: {
        ...CommandContext.getCommandContext(),
        selection: {
          workspaceBoardSuggestionId: workspaceBoardSuggestion.id,
        },
      },
      deduplicationId: applySuggestionRequestId,
      requestId: applySuggestionRequestId,
    };

    await commandExecutor.executeCommand(command, commandRequest);
  }, [commandExecutor, applySuggestionRequestId]);

  return (
    <>
      <Chip label={workspaceBoardSuggestion.status} />
      <div dangerouslySetInnerHTML={{ __html: workspaceBoardSuggestion.suggestedContent }} />
      {workspaceBoardSuggestion.status !== "Accepted" && (
        <>
          <Link onClick={applySuggestion}>{t("Components.WorkspaceBoardSuggestion.Accept")}</Link>
        </>
      )}
    </>
  );
}
