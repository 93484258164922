import { ICommandResponseStatus } from "@bigpi/cookbook";
import { Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  OnWorkspaceBoardTableOfContentsCreateSubscription,
  OnWorkspaceBoardTableOfContentsCreateSubscriptionVariables,
  OnWorkspaceBoardTableOfContentsReactionCreateSubscription,
  OnWorkspaceBoardTableOfContentsReactionCreateSubscriptionVariables,
  useOnCommandStatusChangedSubscription,
  useOnWorkspaceBoardTableOfContentsDeleteSubscription,
  useOnWorkspaceBoardTableOfContentsReactionDeleteSubscription,
  useOnWorkspaceBoardTableOfContentsUpdateSubscription,
  useWorkspaceBoardTableOfContentsQuery,
  useWorkspaceBoardTableOfContentsReactionsQuery,
  WorkspaceBoardTableOfContentsQuery,
  WorkspaceBoardTableOfContentsReactionsQuery,
} from "GraphQL/Generated/Apollo";
import { WORKSPACE_BOARD_TABLE_OF_CONTENTS_CREATE_SUBSCRIPTION } from "GraphQL/WorkspaceBoardTableOfContents/Subscription";
import { WORKSPACE_BOARD_TABLE_OF_CONTENTS_REACTION_CREATE_SUBSCRIPTION } from "GraphQL/WorkspaceBoardTableOfContentsReaction/Subscription";
import { CustomTreeItem } from "./CustomTreeItem";

// *********************************************
// Types/Constants
// *********************************************/
interface IWorkspaceBoardTableOfContentsProps {
  requestId: string;
  shapeId: string;
  workspaceBoardId: string;
}

export type WorkspaceBoardTableOfContentsType = NonNullable<
  WorkspaceBoardTableOfContentsQuery["workspaceBoardTableOfContents"]
>[0] & {
  children: Array<WorkspaceBoardTableOfContentsType>;
  reactions: Array<WorkspaceBoardTableOfContentsReactionsType>;
};

export type WorkspaceBoardTableOfContentsReactionsType = NonNullable<
  WorkspaceBoardTableOfContentsReactionsQuery["workspaceBoardTableOfContentsReactions"]
>[0];

const LOADING_COMMAND_STATUS_LIST = ["queued", "running", "progress"];

// *********************************************
// Components
// *********************************************/
export function WorkspaceBoardTableOfContents(props: IWorkspaceBoardTableOfContentsProps) {
  const { requestId, shapeId, workspaceBoardId } = props;
  const [workspaceBoardTOC, setWorkspaceBoardTOC] = useState<Array<WorkspaceBoardTableOfContentsType>>([]);
  const [commandStatus, setCommandStatus] = useState("");
  const [expandedTOC, setExpandedTOC] = useState<Array<string>>([]);
  const { t } = useTranslation();

  // Update subscription
  useOnWorkspaceBoardTableOfContentsUpdateSubscription({
    variables: {
      shapeId,
      workspaceBoardId,
    },
  });

  // Command status subscription
  useOnCommandStatusChangedSubscription({
    variables: {
      id: requestId,
    },
    onData: (options) => {
      const commandStatus = options.data.data?.onCommandStatusChanged?.status as ICommandResponseStatus | undefined;

      setCommandStatus(commandStatus || "");
    },
  });

  // Delete subscription
  useOnWorkspaceBoardTableOfContentsDeleteSubscription({
    variables: {
      shapeId,
      workspaceBoardId,
    },
    onData: (options) => {
      const workspaceBoardTableOfContents = options.data.data?.onWorkspaceBoardTableOfContentsDelete;

      if (workspaceBoardTableOfContents) {
        const cache = options.client.cache;
        const id = cache.identify({
          __typename: "WorkspaceBoardTableOfContents",
          id: workspaceBoardTableOfContents.id,
        });
        if (id) {
          cache.evict({ id });
        }
      }
    },
  });

  useOnWorkspaceBoardTableOfContentsReactionDeleteSubscription({
    variables: {
      shapeId,
      workspaceBoardId,
    },
    onData: (options) => {
      const workspaceBoardTableOfContentsReaction = options.data.data?.onWorkspaceBoardTableOfContentsReactionDelete;

      if (workspaceBoardTableOfContentsReaction) {
        const cache = options.client.cache;
        const id = cache.identify({
          __typename: "WorkspaceBoardTableOfContentsReaction",
          id: workspaceBoardTableOfContentsReaction.id,
        });
        if (id) {
          cache.evict({ id });
        }
      }
    },
  });

  const { data, subscribeToMore, loading } = useWorkspaceBoardTableOfContentsQuery({
    variables: {
      input: {
        shapeId,
        workspaceBoardId,
      },
    },
  });

  const { data: reactions, subscribeToMore: reactionsSubscribeMore } = useWorkspaceBoardTableOfContentsReactionsQuery({
    variables: {
      filters: {
        shapeId,
        workspaceBoardId,
      },
    },
  });

  useEffect(() => {
    const unsubscribeCreated = subscribeToMore<
      OnWorkspaceBoardTableOfContentsCreateSubscription,
      OnWorkspaceBoardTableOfContentsCreateSubscriptionVariables
    >({
      document: WORKSPACE_BOARD_TABLE_OF_CONTENTS_CREATE_SUBSCRIPTION,
      variables: {
        shapeId,
        workspaceBoardId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        // Return previous data if no new data
        if (!subscriptionData.data || !subscriptionData.data.onWorkspaceBoardTableOfContentsCreate) {
          return prev;
        }
        const newItem = subscriptionData.data.onWorkspaceBoardTableOfContentsCreate;

        return Object.assign({}, prev, {
          workspaceBoardTableOfContents: [
            ...(Array.isArray(prev.workspaceBoardTableOfContents) ? prev.workspaceBoardTableOfContents : []),
            {
              ...newItem,
              children: [],
              reactions: [],
            },
          ],
        });
      },
    });

    return () => {
      unsubscribeCreated();
    };
  }, [subscribeToMore, shapeId]);

  useEffect(() => {
    const unsubscribeCreated = reactionsSubscribeMore<
      OnWorkspaceBoardTableOfContentsReactionCreateSubscription,
      OnWorkspaceBoardTableOfContentsReactionCreateSubscriptionVariables
    >({
      document: WORKSPACE_BOARD_TABLE_OF_CONTENTS_REACTION_CREATE_SUBSCRIPTION,
      variables: {
        shapeId,
        workspaceBoardId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        // Return previous data if no new data
        if (!subscriptionData.data || !subscriptionData.data.onWorkspaceBoardTableOfContentsReactionCreate) {
          return prev;
        }
        const newItem = subscriptionData.data.onWorkspaceBoardTableOfContentsReactionCreate;

        return Object.assign({}, prev, {
          workspaceBoardTableOfContentsReactions: [
            ...(Array.isArray(prev.workspaceBoardTableOfContentsReactions) ? prev.workspaceBoardTableOfContentsReactions : []),
            {
              ...newItem,
              children: [],
              reactions: [],
            },
          ],
        });
      },
    });

    return () => {
      unsubscribeCreated();
    };
  }, [reactionsSubscribeMore, workspaceBoardId, shapeId]);

  useEffect(() => {
    if (data?.workspaceBoardTableOfContents) {
      const reactionsMap = groupedReactions(reactions?.workspaceBoardTableOfContentsReactions || []);
      setWorkspaceBoardTOC(
        buildHierarchy(data?.workspaceBoardTableOfContents as Array<WorkspaceBoardTableOfContentsType>, reactionsMap),
      );

      const allParentIds: Array<string> = [];
      data.workspaceBoardTableOfContents.forEach((item) => {
        if (item.parentId) {
          allParentIds.push(item.parentId);
        }
      });

      if (allParentIds) {
        setExpandedTOC(allParentIds);
      }
    }
  }, [data, reactions]);

  const onExpandedItemsChange = useCallback((event: React.SyntheticEvent, itemIds: Array<string>) => {
    setExpandedTOC(itemIds);
  }, []);

  return (
    <>
      {!loading && workspaceBoardTOC.length > 0 && (
        <SimpleTreeView onExpandedItemsChange={onExpandedItemsChange} expandedItems={expandedTOC}>
          {workspaceBoardTOC.map((item, index) => {
            return (
              <CustomTreeItem
                key={index}
                itemId={item.id}
                label={item.title}
                reactions={item.reactions || []}
                children={item.children || []}
              />
            );
          })}
        </SimpleTreeView>
      )}
      {!loading && workspaceBoardTOC.length === 0 && (
        <Typography variant="body1">
          {t(`Components.HtmlDocumentCompanion.Questionnaire.Tabs.TableOfContents.NoContent`)}
        </Typography>
      )}
    </>
  );
}

/**
 * Build a hierarchy from workspaceBoardTableOfContents data.
 *
 * @param flatData workspaceBoardTableOfContents data.
 *
 * @returns Hierarchy of workspaceBoardTableOfContents data.
 */
function buildHierarchy(
  flatData: Array<WorkspaceBoardTableOfContentsType>,
  reactionsMap?: Record<string, Array<WorkspaceBoardTableOfContentsReactionsType>>,
) {
  if (!flatData) {
    return [];
  }
  // Step 1: Create a lookup map for each item by its ID
  const lookup = new Map<string, WorkspaceBoardTableOfContentsType>();

  // Step 2: Initialize the lookup map with each item
  flatData.forEach((item) => {
    lookup.set(item.id, { ...item, children: [], reactions: reactionsMap ? reactionsMap[item.id] : [] });
  });

  // Step 3: Populate the hierarchy
  flatData.forEach((item) => {
    if (item.parentId) {
      const parentItem = lookup.get(item.parentId);
      // Take the lookup item, it has the reactions
      const lookupItem = lookup.get(item.id);
      if (parentItem && lookupItem) {
        parentItem.children.push(lookupItem);
        lookup.delete(item.id);
      }
    }
  });

  return Array.from(lookup.values());
}

/**
 * Group reactions by workspace board table of contents ID.
 *
 * @param allReactions All workspace board table of contents reactions.
 *
 * @returns Grouped reactions by workspace board table of contents ID.
 */
function groupedReactions(allReactions: Array<WorkspaceBoardTableOfContentsReactionsType>) {
  const reactions = allReactions.reduce(
    (acc, reaction) => {
      if (reaction && reaction.workspaceBoardTableOfContentsId) {
        const key = reaction.workspaceBoardTableOfContentsId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(reaction);
      }
      return acc;
    },
    {} as Record<string, Array<WorkspaceBoardTableOfContentsReactionsType>>,
  );

  return reactions;
}
