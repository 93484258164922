import { useAuthUser } from "@frontegg/react";
import { Stack } from "@mui/material";
import { TreeItem2Label, TreeItem2SlotProps } from "@mui/x-tree-view/TreeItem2";
import { useCallback } from "react";

import { EmojiReactions } from "Components/EmojiReactions/EmojiReactions";
import { UserReactions } from "Components/UserReactions/UserReactions";
import {
  useCreateWorkspaceBoardTableOfContentsReactionMutation,
  useDeleteWorkspaceBoardTableOfContentsReactionMutation,
} from "GraphQL/Generated/Apollo";
import { WorkspaceBoardTableOfContentsType } from "./WorkspaceBoardTableOfContents";

// *********************************************
// Types/Constants
// *********************************************/
export type ICustomTreeItemLabelProps = TreeItem2SlotProps["label"] & {
  itemId: string;
  reactions?: WorkspaceBoardTableOfContentsType["reactions"];
};

// *********************************************
// Component
// *********************************************/
export function CustomTreeItemLabel(props: ICustomTreeItemLabelProps) {
  const { itemId, reactions, ...otherProps } = props;
  const [upsertWorkspaceBoardTableOfContentsReaction] = useCreateWorkspaceBoardTableOfContentsReactionMutation();
  const [deleteWorkspaceBoardTableOfContentsReaction] = useDeleteWorkspaceBoardTableOfContentsReactionMutation();
  const user = useAuthUser();

  const onAddReaction = useCallback(
    (reaction: string) => {
      upsertWorkspaceBoardTableOfContentsReaction({
        variables: {
          input: {
            workspaceBoardTableOfContentsId: itemId,
            reaction,
          },
        },
        refetchQueries: ["WorkspaceBoardTableOfContents"],
      });
    },
    [itemId, upsertWorkspaceBoardTableOfContentsReaction],
  );

  const onDeleteReaction = useCallback(
    (id: string) => {
      deleteWorkspaceBoardTableOfContentsReaction({
        variables: {
          input: {
            workspaceBoardTableOfContentsReactionId: id,
          },
        },
        refetchQueries: ["WorkspaceBoardTableOfContents"],
      });
    },
    [deleteWorkspaceBoardTableOfContentsReaction],
  );

  const onReactionClick = useCallback(
    (reaction: string, event?: React.MouseEvent) => {
      if (reactions) {
        const reactionObject = reactions.find(
          (reactionObject) => reactionObject.userId === user.id && reactionObject.reaction === reaction,
        );
        if (reactionObject) {
          onDeleteReaction(reactionObject.id);
        } else {
          onAddReaction(reaction);
        }
      }
      event?.stopPropagation();
    },
    [onAddReaction, onDeleteReaction, reactions, user],
  );

  return (
    <Stack direction="row" justifyContent="space-between" width="100%" alignItems={"center"}>
      <TreeItem2Label {...otherProps} />
      <Stack flexDirection="row" justifyContent="space-around">
        {reactions && <UserReactions reactions={reactions} onReactionClick={onReactionClick} />}
        <EmojiReactions onReactionClick={onAddReaction} />
      </Stack>
    </Stack>
  );
}
