import { ItemGridSize } from "@bigpi/cookbook";
import { Star, ViewCozy } from "@mui/icons-material";
import { Box, CardActions, CardMedia } from "@mui/material";
import { ButtonBase, Card, CardHeader, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { WorkspaceBoardListMenu } from "./WorkspaceBoardListMenu";
import { WorkspaceTagsList } from "Components/WorkspaceTagsList/WorkspaceTagsList";
import { WorkspaceBoardsQuery, useWorkspaceQuery } from "GraphQL/Generated/Apollo";
import { getImageSrcFromImageBase64 } from "Utils/DomToImageUtils";
import { formatWorkspaceUpdatedAt } from "Utils/WorkspaceUtils";

export interface IWorkspaceBoardGridItemProps {
  workspaceBoardDetails: WorkspaceBoardsQuery["workspaceBoards"][number];
  size: ItemGridSize;
}

export function WorkspaceBoardGridItem(props: IWorkspaceBoardGridItemProps) {
  const { workspaceBoardDetails, size } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: workspaceData } = useWorkspaceQuery({
    variables: { id: workspaceBoardDetails.workspaceId },
  });
  const defaultWorkspaceBoardId = workspaceData?.workspace?.defaultWorkspaceBoard?.id;

  const navigateToWorkspaceBoard = useCallback(() => {
    navigate(`/workspaces/${workspaceBoardDetails.workspaceId}/boards/${workspaceBoardDetails.id}`);
  }, [navigate, workspaceBoardDetails.id, workspaceBoardDetails.workspaceId]);

  return (
    <Grid
      item
      xl={size === ItemGridSize.Small ? 3 : size === ItemGridSize.Medium ? 4 : 6}
      lg={size === ItemGridSize.Small ? 2 : size === ItemGridSize.Medium ? 3 : 4}
      md={size === ItemGridSize.Small ? 3 : size === ItemGridSize.Medium ? 4 : 6}
      xs={size === ItemGridSize.Small ? 6 : size === ItemGridSize.Medium ? 6 : 12}
      sx={{ display: "grid" }}
    >
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderWidth: "2px",
          backgroundColor: "#e8eaeb",
          ":hover": {
            backgroundColor: "#d8dadb",
          },
        }}
      >
        <CardHeader
          avatar={
            defaultWorkspaceBoardId && defaultWorkspaceBoardId === workspaceBoardDetails.id ? (
              <Star className="workspace-board-card-avatar" />
            ) : (
              <ViewCozy className="workspace-board-card-avatar" />
            )
          }
          title={
            <ButtonBase sx={{ textAlign: "start" }} onClick={navigateToWorkspaceBoard} disableRipple>
              <Typography
                sx={{
                  fontSize: size === ItemGridSize.Small ? "1rem" : size === ItemGridSize.Medium ? "1.1rem" : "1.2rem",
                  fontWeight: "500",
                  wordBreak: "break-word",
                }}
              >
                {workspaceBoardDetails.name}
              </Typography>
            </ButtonBase>
          }
          action={
            <WorkspaceBoardListMenu workspaceId={workspaceBoardDetails.workspaceId} workspaceBoardId={workspaceBoardDetails.id} />
          }
        />

        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
          <ButtonBase
            sx={{ width: "90%", height: "100%", backgroundColor: "#fff", borderRadius: 1 }}
            onClick={navigateToWorkspaceBoard}
            disableRipple
          >
            <CardMedia sx={{ width: "100%", minHeight: "100px", display: "flex", flexWrap: "wrap", alignContent: "center" }}>
              {workspaceBoardDetails.thumbnail ? (
                <img width="100%" style={{ padding: "6px" }} src={getImageSrcFromImageBase64(workspaceBoardDetails.thumbnail)} />
              ) : (
                <Box sx={{ width: "100%" }}>
                  <ViewCozy />
                </Box>
              )}
            </CardMedia>
          </ButtonBase>
        </Box>

        <CardActions sx={{ display: "block", bottom: 0 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }} component="span">
            <WorkspaceTagsList
              tagsList={workspaceBoardDetails.tags}
              chipSxProps={{ backgroundColor: "#d8dadb" }}
              highlightTemplateTag={false}
            />
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography variant="body2">{workspaceBoardDetails.description || ""}</Typography>
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography variant="caption" color="text.secondary">
              {formatWorkspaceUpdatedAt(workspaceBoardDetails.updatedAt, t)}
            </Typography>
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
}
