import { CommandActionType } from "../CommandActionType.js";
import { CommandOutputType } from "../CommandOutputType.js";
import { ICommand } from "../ICommand.js";
import { DataType, EntityType } from "../ICommandInput.js";

export const COMMAND_GENERATE_BOARD_QUESTIONNAIRE_SUGGESTIONS_TEMPLATE: ICommand = {
  id: "92c08761-b42b-4a56-92cb-34faccde161e",
  name: "Suggest answers to all questions in the document",
  description: "",
  action: {
    actionType: CommandActionType.SuggestAllAnswersAction,
    data: {},
  },
  inputs: [
    {
      dataType: {
        dataType: DataType.String,
        isArray: false,
        validation: "",
      },
      fieldName: "shapeId",
      fieldNameAliases: [],
      entityType: EntityType.String,
      defaultValue: "",
      label: "",
      visible: false,
      required: true,
    },
    {
      dataType: {
        dataType: DataType.String,
        isArray: false,
        validation: "",
      },
      fieldName: "workspaceBoardId",
      fieldNameAliases: [],
      entityType: EntityType.WorkspaceBoard,
      defaultValue: "",
      label: "",
      visible: false,
      required: true,
    },
  ],
  inputTemplate: `{ "shapeId": "{{ shapeId }}", "workspaceBoardId": "{{ workspaceBoardId }}" }`,
  isBlocking: false,
  outputTypes: [CommandOutputType.None],
};
