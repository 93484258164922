import {
  COMMAND_EXTRACT_QUESTIONS_TEMPLATE,
  COMMAND_GENERATE_BOARD_QUESTIONNAIRE_SUGGESTIONS_TEMPLATE,
  DocumentType,
  ICommandRequest,
} from "@bigpi/cookbook";
import UpdateIcon from "@mui/icons-material/Update";

import { ICompanionTab } from "BoardComponents/Companion/ICompanionTab";
import { WorkspaceBoardTableOfContents } from "Components/WorkspaceBoardTableOfContents/WorkspaceBoardTableOfContents";
import { CommandContext } from "CommandContext";
import { HtmlDocumentCompanionPlugInBase, IHtmlDocumentCompanionPlugInInputs } from "./HtmlDocumentCompanionPlugInBase";
import { WorkspaceBoardSuggestions } from "Components/WorkspaceBoardSuggestions/WorkspaceBoardSuggestions";

export enum QuestionnaireCompanionTab {
  TableOfContents = "TableOfContents",
  Suggestions = "Suggestions",
}

export class QuestionnaireCompanionPlugIn extends HtmlDocumentCompanionPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = DocumentType.Questionnaire;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: IHtmlDocumentCompanionPlugInInputs): Promise<Array<ICompanionTab>> {
    const { commandExecutor, organizationId, shapeId, t, workspaceBoardId } = input;

    const extractQuestionsRequestId = `generate-toc-${shapeId}`;
    const generateSuggestionsRequestId = `workspace-board-shape-suggest-${shapeId}`;
    const generateSuggestions = async () => {
      const command = {
        ...COMMAND_GENERATE_BOARD_QUESTIONNAIRE_SUGGESTIONS_TEMPLATE,
      };

      // Create the overrides/values that are not set automatically by CommandExecutor
      const commandRequest: Partial<ICommandRequest> = {
        deduplicationId: generateSuggestionsRequestId,
        requestId: generateSuggestionsRequestId,
      };

      // Set the selected document into the context to be picked up from there
      CommandContext.patchCommandContext({
        selection: {
          workspaceBoardId,
          shapeId,
        },
      });

      await commandExecutor.executeCommand(command, commandRequest);
    };

    const extractQuestions = async () => {
      const command = {
        ...COMMAND_EXTRACT_QUESTIONS_TEMPLATE,
      };

      // Create the overrides/values that are not set automatically by CommandExecutor
      const extractQuestionsRequestId = `generate-toc-${shapeId}`;

      const commandRequest: Partial<ICommandRequest> = {
        // Create deduplicationId
        deduplicationId: extractQuestionsRequestId,
        requestId: extractQuestionsRequestId,
      };

      // Set the selected document into the context to be picked up from there
      CommandContext.patchCommandContext({
        selection: {
          workspaceBoardId,
          shapeId,
        },
      });

      await commandExecutor.executeCommand(command, commandRequest);
    };

    return Promise.resolve([
      {
        tabId: QuestionnaireCompanionTab.TableOfContents,
        title: t(`Components.HtmlDocumentCompanion.Questionnaire.Tabs.${QuestionnaireCompanionTab.TableOfContents}.Title`),
        children: (
          <WorkspaceBoardTableOfContents
            workspaceBoardId={workspaceBoardId}
            shapeId={shapeId}
            requestId={extractQuestionsRequestId}
          />
        ),
        actions: [
          {
            title: (
              <>
                <UpdateIcon sx={{ mr: 1 }} />
                {t(
                  `Components.HtmlDocumentCompanion.Questionnaire.Tabs.${QuestionnaireCompanionTab.TableOfContents}.Actions.Update`,
                )}
              </>
            ),
            onAction: extractQuestions,
            disabled: false,
            value: "generate-toc",
          },
        ],
      },
      {
        tabId: QuestionnaireCompanionTab.Suggestions,
        title: t(`Components.HtmlDocumentCompanion.Questionnaire.Tabs.${QuestionnaireCompanionTab.Suggestions}.Title`),
        children: <WorkspaceBoardSuggestions workspaceBoardId={workspaceBoardId} shapeId={shapeId}></WorkspaceBoardSuggestions>,
        actions: [
          {
            title: t(
              `Components.HtmlDocumentCompanion.Questionnaire.Tabs.${QuestionnaireCompanionTab.Suggestions}.Actions.Generate`,
            ),
            onAction: generateSuggestions,
            disabled: false,
            value: "generate-answers",
          },
        ],
      },
    ]);
  }
}
