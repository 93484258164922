import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  OnWorkspaceBoardSuggestionCreateSubscription,
  OnWorkspaceBoardSuggestionCreateSubscriptionVariables,
  useOnWorkspaceBoardSuggestionCreateSubscription,
  useOnWorkspaceBoardSuggestionDeleteSubscription,
  useOnWorkspaceBoardSuggestionUpdateSubscription,
  useWorkspaceBoardSuggestionsQuery,
} from "GraphQL/Generated/Apollo";
import { WORKSPACE_BOARD_SUGGESTION_CREATE_SUBSCRIPTION } from "GraphQL/WorkspaceBoardSuggestion/Subscription";
import { WorkspaceBoardSuggestion } from "./WorkspaceBoardSuggestion";

// *********************************************
// Interfaces
// *********************************************/
export interface IWorkspaceBoardSuggestionsProps {
  workspaceBoardId: string;
  shapeId: string;
}

// *********************************************
// Public methods
// *********************************************/
export function WorkspaceBoardSuggestions(props: IWorkspaceBoardSuggestionsProps) {
  const { workspaceBoardId, shapeId } = props;
  const { t } = useTranslation();

  const { data, error, loading, subscribeToMore } = useWorkspaceBoardSuggestionsQuery({
    variables: {
      input: {
        workspaceBoardId,
        shapeId,
      },
    },
  });

  // These keep the cache up-to-date while we're here
  useOnWorkspaceBoardSuggestionCreateSubscription({
    variables: {
      workspaceBoardId,
      shapeId,
    },
  });

  useOnWorkspaceBoardSuggestionUpdateSubscription({
    variables: {
      workspaceBoardId,
      shapeId,
    },
  });

  useOnWorkspaceBoardSuggestionDeleteSubscription({
    variables: {
      workspaceBoardId,
      shapeId,
    },
    onData: (options) => {
      const suggestion = options.data.data?.onWorkspaceBoardSuggestionDelete;

      if (suggestion) {
        const cache = options.client.cache;
        const id = cache.identify({
          __typename: "WorkspaceBoardSuggestion",
          id: suggestion.id,
        });
        if (id) {
          cache.evict({ id });
        }
      }
    },
  });

  useEffect(() => {
    const unsubscribeCreated = subscribeToMore<
      OnWorkspaceBoardSuggestionCreateSubscription,
      OnWorkspaceBoardSuggestionCreateSubscriptionVariables
    >({
      document: WORKSPACE_BOARD_SUGGESTION_CREATE_SUBSCRIPTION,
      variables: {
        workspaceBoardId,
        shapeId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.onWorkspaceBoardSuggestionCreate) {
          return prev;
        }

        const newSuggestion = subscriptionData.data.onWorkspaceBoardSuggestionCreate;

        return Object.assign({}, prev, {
          workspaceBoardSuggestions: [
            ...(Array.isArray(prev.workspaceBoardSuggestions) ? prev.workspaceBoardSuggestions : []),
            newSuggestion,
          ],
        });
      },
    });

    return () => {
      unsubscribeCreated();
    };
  }, [subscribeToMore, workspaceBoardId, shapeId]);

  if (!loading && data?.workspaceBoardSuggestions?.length) {
    return (
      <Box>
        {data.workspaceBoardSuggestions.map((suggestion) => {
          return <WorkspaceBoardSuggestion workspaceBoardSuggestion={suggestion} />;
        })}
      </Box>
    );
  } else {
    // TODO: Refactor the translation key location
    return (
      <Typography variant="body1">{t(`Components.HtmlDocumentCompanion.Questionnaire.Tabs.Suggestions.NoContent`)}</Typography>
    );
  }
}
