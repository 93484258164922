import { CONFIG_KEY_ORGANIZATION_PREFERENCES } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import { IconButton, ClickAwayListener } from "@mui/material";
import { Box } from "@mui/system";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { useCallback, useEffect, useState } from "react";

import { useGetConfigDataQuery } from "GraphQL/Generated/Apollo";

// *********************************************
// Types/Constants
// *********************************************/
/*
 * These defaults are copied from emoji-picker-react.
 * We can see all the list of unified emojis here:
 *   https://projects.iamcal.com/emoji-data/table.htm (emoji-picker-react internally uses this list)
 */
export const DEFAULT_REACTIONS = [
  "1f44d", // 👍
  "2764-fe0f", // ❤️
  "1f603", // 😃
  "1f622", // 😢
  "1f64f", // 🙏
  "1f44e", // 👎
  "1f621", // 😡
];

export interface IEmojiReactionsProps {
  onReactionClick: (reaction: string, event?: MouseEvent) => void;
}

// *********************************************
// Component
// *********************************************/
export function EmojiReactions(props: IEmojiReactionsProps) {
  const { onReactionClick } = props;
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [availableReactions, setAvailableReactions] = useState<Array<string>>(DEFAULT_REACTIONS);

  const { data: appConfigDataResult } = useGetConfigDataQuery({
    variables: {
      key: CONFIG_KEY_ORGANIZATION_PREFERENCES,
      organizationId: useAuthUser()?.tenantId,
    },
  });

  useEffect(() => {
    const configData = JSON.parse(appConfigDataResult?.Config?.data || "{}");
    setAvailableReactions(configData?.reactions || DEFAULT_REACTIONS);
  }, [appConfigDataResult]);

  const onClickAway = useCallback(() => {
    setShowEmojiPicker(false);
  }, []);

  const onShowEmojiPicker = useCallback(() => {
    setShowEmojiPicker(true);
  }, []);

  const onReaction = useCallback(
    (reaction: string, event: MouseEvent) => {
      onReactionClick(reaction, event);
      setShowEmojiPicker(false);
    },
    [onReactionClick],
  );

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box>
        <IconButton onClick={onShowEmojiPicker}>
          <AddReactionOutlinedIcon />
        </IconButton>
        {showEmojiPicker && (
          <Box sx={{ position: "absolute", top: -40, right: 0, zIndex: 2 }}>
            <EmojiPicker
              reactionsDefaultOpen={true}
              onReactionClick={(data: EmojiClickData, event) => {
                onReaction(data.emoji, event);
              }}
              onEmojiClick={(data: EmojiClickData, event) => {
                onReaction(data.emoji, event);
              }}
              reactions={availableReactions}
              previewConfig={{ showPreview: false }}
            />
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
}
