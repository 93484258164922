import { gql } from "@apollo/client";

export const WORKSPACE_BOARD_SUGGESTION_CREATE_SUBSCRIPTION = gql`
  subscription OnWorkspaceBoardSuggestionCreate($workspaceBoardId: ID!, $shapeId: ID!) {
    onWorkspaceBoardSuggestionCreate(workspaceBoardId: $workspaceBoardId, shapeId: $shapeId) {
      blockIds
      generatedBlockIds
      id
      originalContent
      references {
        id
        excerpt
        sourceUri
      }
      shapeId
      status
      suggestedContent
      suggestionDescription
      workspaceBoardId
    }
  }
`;

export const WORKSPACE_BOARD_SUGGESTION_UPDATE_SUBSCRIPTION = gql`
  subscription OnWorkspaceBoardSuggestionUpdate($workspaceBoardId: ID!, $shapeId: ID!) {
    onWorkspaceBoardSuggestionUpdate(workspaceBoardId: $workspaceBoardId, shapeId: $shapeId) {
      blockIds
      generatedBlockIds
      id
      originalContent
      references {
        id
        excerpt
        sourceUri
      }
      shapeId
      status
      suggestedContent
      suggestionDescription
      workspaceBoardId
    }
  }
`;

export const WORKSPACE_BOARD_SUGGESTION_DELETE_SUBSCRIPTION = gql`
  subscription OnWorkspaceBoardSuggestionDelete($workspaceBoardId: ID!, $shapeId: ID!) {
    onWorkspaceBoardSuggestionDelete(workspaceBoardId: $workspaceBoardId, shapeId: $shapeId) {
      id
    }
  }
`;
