import { Button } from "@mui/material";

import { SplitButton, SplitButtonProps } from "Components/SplitButton/SplitButton";
import { ICompanionTabAction } from "./ICompanionTabAction";

export interface ICompanionTabActionsProps {
  tabActions: Array<ICompanionTabAction>;
}

export function CompanionTabActions(props: ICompanionTabActionsProps) {
  const { tabActions } = props;

  if (tabActions.length === 0) {
    return <></>;
  }

  if (tabActions.length === 1) {
    return (
      <Button variant="contained" onClick={tabActions[0].onAction} sx={{ maxHeight: "40px" }}>
        {tabActions[0].title}
      </Button>
    );
  }

  const splitButtonOptions: SplitButtonProps["options"] = tabActions.map((action) => {
    return {
      value: action.value,
      label: action.title,
    };
  });

  return (
    <SplitButton
      options={splitButtonOptions}
      handleClick={(option) => {
        const action = tabActions.find((action) => action.value === option);
        if (action) {
          action.onAction();
        }
      }}
      sx={{ maxHeight: "40px" }}
    />
  );
}
