import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { SxProps, Theme } from "@mui/system";
import * as React from "react";

export const OPTION_VALUE_DIVIDER = "divider";

export interface SplitButtonProps {
  handleClick: (option: string) => void;
  options: Array<{ value: string; label: React.ReactNode | string; disabled?: boolean; sx?: Record<string, any> }>;
  sx?: SxProps<Theme>;
}

/**
 * Reference: https://mui.com/material-ui/react-button-group/#split-button
 * Component to display a button with a dropdown menu.
 */
export function SplitButton(props: SplitButtonProps) {
  const { options, sx } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // Handles button click
  const handleClick = () => {
    props.handleClick(options[selectedIndex].value);
  };

  // Handles menu item click
  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setOpen(false);
    props.handleClick(options[index].value);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" sx={sx}>
        {/* Displays selected button */}
        <Button onClick={handleClick} disabled={options[selectedIndex].disabled}>
          {options[selectedIndex].label}
        </Button>
        {options.length > 1 && (
          <Button
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
            size="small"
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>

      {/* Displays options */}
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ zIndex: 20 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => {
                    if (option.value === OPTION_VALUE_DIVIDER) {
                      return <Divider key={index} />;
                    } else {
                      return (
                        <MenuItem
                          sx={option.sx}
                          key={option.value}
                          disabled={option.disabled}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    }
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
